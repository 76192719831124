@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;700;800&display=swap');

body {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100dvh;
}

#root {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: hidden;
}

.maplibregl-ctrl-attrib {
  font-family: "Nunito Sans", "Roboto", sans-serif;
  font-size: 0.8em;
}

.map-tooltip {
  z-index: 4;
}

.map-tooltip .maplibregl-popup-content {
  padding: 4px 8px;
  width: 250px;
}

.map-tooltip .maplibregl-popup-content h3,
.map-tooltip .maplibregl-popup-content p {
  margin: 0;
}
